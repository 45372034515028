import React from "react";
import cn from "classnames";
import { useRecoilState } from "recoil";
import { transactionHashState } from "../../../../states/transactionHashState";
import { buyAmountState } from "../../../../states/buyAmountState";
import styles from "./Successfully.module.sass";
import { Link } from "react-router-dom";

const Successfully = () => {
  const [transactionHash, setTransactionHash] = useRecoilState(transactionHashState);
  const [withdrawAmount, setWithdrawAmount] = useRecoilState(buyAmountState);

  const processTransactionHash = (hash) => {
    if (hash == null) {
      return "Sending...";
    }
    return hash.substring(0, 6) + "..." + hash.substring(hash.length - 4);
  };

  const polygonScanLink = `https://polygonscan.com/tx/${transactionHash}`;

  const items = [
    {
      title: "Status",
      content: "Completed",
      color: "#58BD7D",
    },
    {
      title: "Transaction ID",
      content: processTransactionHash(transactionHash),
    },
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Yay!{" "}
          <span role="img" aria-label="firework">
            🎉
          </span>
        </div>
        <div className={styles.info}>
          You successfully withdrew <span>{withdrawAmount} USDT</span> from Rypto!
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.btns}>
          <a
            href={polygonScanLink}
            target="_blank"
            rel="noopener noreferrer"
            className={cn("button-stroke", styles.button)}
          >
            View on PolygonScan
          </a>
          <Link className={cn("button", styles.button)} to="/">
            Trade
          </Link>
        </div>
      </div>
    </>
  );
};

export default Successfully