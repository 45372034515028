import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { walletState } from "../../../states/walletState";
import { useRecoilState } from "recoil";
import { URI } from "../../../config/Contracts";
import { symbolState } from "../../../states/symbolState";
import { useRecoilValue } from "recoil";

const Table = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [pressedButton, setPressedButton] = useState("AccountTrades");
  const [account] = useRecoilState(walletState);
  const symbol = useRecoilValue(symbolState);
  const navigation = [symbol.toUpperCase() + " orders", "USDI orders"];
  
  const [sorting, setSorting] = useState(navigation[0]);
  const convert_date = (date) => {
    const utcDate = new Date(date + "Z"); 
    const localTimeOffset = utcDate.getTimezoneOffset() * 60; 
    return Math.floor(utcDate.getTime()) - localTimeOffset;
  };

  // Función para obtener los datos del nuevo endpoint cuando se selecciona "Market trades"
  const fetchRyptoStable = async () => {
    if (!account) return;
    try {
      const response = await fetch( 
        `${URI}/order/rypto-stable?user_address=${account}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      console.log("Events: " + JSON.stringify(data.data));

      // Formatear los datos obtenidos de la API para los items
      const formattedItems = data.data.map((order) => ({
        type: order.type.charAt(0).toUpperCase() + order.type.slice(1),
        time: convert_date(order.timestamp),
        price: order.price == null ? "-" : order.price,
        amount: ((order.amount ? order.amount : order.ryptoStableAmount) / 1e18).toFixed(5), 
        total: ((order.amount ? order.amount : order.ryptoStableAmount) / 1e18 * order.price).toFixed(5),
      }));
      const orderedItems = formattedItems.sort((a, b) => {
        if (a.time < b.time) {
          return 1;
        }
        if (a.time > b.time) {
          return -1;
        }
        return 0;
      });
      setItems(orderedItems);
    } catch (error) {
      console.error("Error fetching market trades:", error);
    }
  };

  // Función para obtener los datos del historial de órdenes (otro endpoint)
  const fetchOrderHystory = async () => {
    if (!account || !symbol) return;
    try {
      const response = await fetch(
        `${URI}/api/order/history?user_address=${account}&symbol=${symbol}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Formatear los datos obtenidos de la API para los items
      const formattedItems = data.data.map((order) => ({
        type: order.type,
        time: convert_date(order.timestamp),
        price: (order.price == null ? 0 : order.price).toFixed(5), // Asumiendo que el precio está en formato wei
        amount: ((1/order.price) * (order.amount / 1e18)).toFixed(5),
        total: (order.amount / 1e18).toFixed(5), // Asumiendo que el amount está en formato wei
        state: order.state,
      }));

      setItems(formattedItems.reverse());
    } catch (error) {
      console.error("Error fetching order history:", error);
    }
  };

  // Función que selecciona el endpoint correcto dependiendo de la pestaña
  const fetchData = () => {
    if (!account) return; // No llamar a la API si no hay una cuenta conectada

    if (pressedButton === "AccountTrades") {
      fetchOrderHystory();
    } else {
      fetchRyptoStable();
    }
  };

  useEffect(() => {
    if (account) {
      fetchData();
      // Limpiar el intervalo anterior y configurar uno nuevo basado en la pestaña seleccionada
      const intervalId = setInterval(fetchData, 500);

      // Limpiar intervalo cuando se desmonta el componente o se cambia de endpoint
      return () => clearInterval(intervalId);
    }
  }, [pressedButton, account, symbol]);

  useEffect(() => {
    setItems([]);
    fetchData();
  }, [symbol]);

  // Función que se ejecuta al cambiar de pestaña
  const handleTabChange = (index) => {
    setActiveIndex(index);

    // Cambiar el endpoint dependiendo de la pestaña seleccionada
    if (index === 1) {
      setPressedButton("RyptoStable");
    } else {
      setPressedButton("AccountTrades");
    }
  };

  // Mostrar mensaje si el usuario no está conectado
  if (!account) {
    return ;
  }

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => handleTabChange(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Type</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Time</div>
          </div>
          {activeIndex == 0 ? <div className={styles.col}>
            <div className="sorting">Price (USDI)</div>
          </div> : null}
          <div className={styles.col}>
            <div className="sorting">Amount ({activeIndex==0 ? symbol.toUpperCase() : "USDT"})</div>
          </div>
          {activeIndex == 0 ? <div className={styles.col}>
            <div className="sorting">State</div>
          </div> : null}
          <div className={styles.col}>
            <div className="sorting">Total ({activeIndex==0 ? "USDI" : "USDI"})</div>
          </div>
        </div>
        {items && items.length > 0 && items.map((x, index) => (
          <div className={styles.row} key={index}>
              <div
                className={cn({
                  [styles.positive]: (x.type === "Buy" && x.state === "Done!") || x.type === "Purchase",
                  [styles.negative]: (x.type === "Sell" && x.state === "Done!")|| x.type === "Withdrawal",
                  [styles.processing]: x.type != "Withdrawal" && x.type != "Purchase" &&  x.state != "Done!",
                })}
              >
                {x.type}
              </div>
            <div className={styles.col}>{new Date(x.time).toLocaleTimeString()}</div>
            {activeIndex==0 ? <div className={styles.col}>
              <div
                className={cn({
                  [styles.positive]: (x.type === "Buy" && x.state === "Done!") || x.type === "Purchase",
                  [styles.negative]: (x.type === "Sell" && x.state === "Done!")|| x.type === "Withdrawal",
                  [styles.processing]: x.type != "Withdrawal" && x.type != "Purchase" &&  x.state != "Done!",
                })}
              >
                {x.price}
              </div>
            </div> : null}
            <div className={styles.col}>{x.amount}</div>
            {activeIndex==0 ? <div className={styles.col}>
              <div
                className={cn({
                  [styles.positive]: (x.type === "Buy" && x.state === "Done!") || x.type === "Purchase",
                  [styles.negative]: (x.type === "Sell" && x.state === "Done!")|| x.type === "Withdrawal",
                  [styles.processing]: x.type != "Withdrawal" && x.type != "Purchase" &&  x.state != "Done!",
                })}
              >
                {x.state}
              </div>
            </div> : null}
            <div className={styles.col}>{x.total}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
