import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import { useRecoilValue } from "recoil"; // Importar hook de Recoil
import { actualPriceState } from "../../../states/actualPriceState";
import { actualOpenPriceState } from "../../../states/actualOpenPriceState";
import { priceColorState } from "../../../states/priceColorState";
import { URI } from "../../../config/Contracts";
import { symbolState } from "../../../states/symbolState";

const sorting = [
    {
        color1: "#FF6838",
        color2: "#B1B5C3",
        color3: "#58BD7D",
    },
    {
        color1: "#B1B5C3",
        color2: "#B1B5C3",
        color3: "#58BD7D",
    },
    {
        color1: "#FF6838",
        color2: "#B1B5C3",
        color3: "#B1B5C3",
    },
];

const counterOptions = ["10", "20", "30"];

const Balance = () => {
    const [counter, setCounter] = useState(counterOptions[0]);
    const [items, setItems] = useState([]);
    const actualPrice = useRecoilValue(actualPriceState);
    const actualOpenPrice = useRecoilValue(actualOpenPriceState);
    const priceColor = useRecoilValue(priceColorState);
    const symbol = useRecoilValue(symbolState);
    

    useEffect(() => {
        const fetchData = async () => {
            if (!symbol) return;
            try {
                const response = await fetch(`${URI}/api/order-book/data?symbol=${symbol}`, {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                const { bids, asks } = data;

                // Map bids and asks to the items format
                const totalBids = bids.reduce((acc, [amount, price, _]) => acc + (amount * price), 0);
                const totalAsks = asks.reduce((acc, [amount, price, _]) => acc + (amount * price), 0);

                const mappedBids = bids.reverse().map(([amount, price, timestamp], index) => (
                {
                    amount: ((1/price) * (amount / 1e18)).toFixed(4), // Assuming price is in wei-like units
                    price: price, // Placeholder amount
                    total: (amount / 1e18).toFixed(4), // Placeholder total
                    positive: true,
                    percent: (amount / totalBids) * 100, // Calculo de porcentaje correcto
                    key: `bid-${index}`,
                }));
                
                const mappedAsks = asks.reverse().map(([amount, price, timestamp], index) => ({
                    amount: ((1/price) * (amount / 1e18)).toFixed(4), // Assuming price is in wei-like units
                    price: price, // Placeholder amount
                    total: (amount / 1e18).toFixed(4), // Placeholder total
                    negative: true,
                    percent: (amount / totalAsks) * 100, // Calculo de porcentaje correcto
                    key: `ask-${index}`,
                }));

                // Combine bids and asks
                const combinedItems = [...mappedAsks, {
                    balance: [
                        {
                            status: true,
                            currency: actualPrice.toFixed(7),
                            price: actualOpenPrice.toFixed(7),
                        },
                    ],
                    key: "balance",
                }, ...mappedBids];

                setItems(combinedItems);
            } catch (error) {
                console.error("Error fetching order book data:", error);
            }
        };
        
        // Initial fetch
        fetchData();

        // Set interval to fetch every 3 seconds
        const interval = setInterval(fetchData, 3000);

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, [actualPrice, symbol]);

    useEffect(() => {
        setItems([]);
    }, [symbol]);

    return (
        <div className={styles.balance}>
            <div className={styles.head}>
                <div className={styles.sorting}>
                    {sorting.map((x, index) => (
                        <button
                            className={cn(styles.direction, {
                                [styles.active]: index < 1,
                            })}
                            key={index}
                        >
                            <span style={{ backgroundColor: x.color1 }}></span>
                            <span style={{ backgroundColor: x.color2 }}></span>
                            <span style={{ backgroundColor: x.color3 }}></span>
                        </button>
                    ))}
                </div>
                <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    classDropdownArrow={styles.dropdownArrow}
                    classDropdownBody={styles.dropdownBody}
                    classDropdownOption={styles.dropdownOption}
                    value={counter}
                    setValue={setCounter}
                    options={counterOptions}
                />
            </div>
            <div className={styles.top}>
                <div className={styles.price}>Price (USDI)</div>
                <div className={styles.amount}>Amounts ({symbol.toUpperCase()})</div>
                <div className={styles.total}>Total</div>
            </div>
            <div className={styles.list}>
                {items.map((x, index) =>
                    x.balance ? (
                        priceColor == "#00C076" ? (
                            <div
                                className={cn(
                                    styles.statistics,
                                    styles.positive
                                )}
                                key={x.key}
                            >
                                <div className={styles.currency}>
                                    {x.balance[0].currency}
                                </div>
                                <Icon name="arrow-top" size="16" />
                                <div className={styles.money}>
                                    {x.balance[0].price}
                                </div>
                            </div>
                        ) : (
                            <div
                                className={cn(
                                    styles.statistics,
                                    styles.negative
                                )}
                                key={x.key}
                            >
                                <div className={styles.currency}>
                                    {x.balance[0].currency}
                                </div>
                                <Icon name="arrow-bottom" size="16" />
                                <div className={styles.money}>
                                    {x.balance[0].price}
                                </div>
                            </div>
                        )
                    ) : (
                        <div
                            className={cn(
                                styles.item,
                                { [styles.positive]: x.positive },
                                { [styles.negative]: x.negative }
                            )}
                            key={x.key}
                        >
                            <div className={styles.price}>{x.price}</div>
                            <div className={styles.amount}>{x.amount}</div>
                            <div className={styles.total}>{x.total}</div>
                            <div
                                className={styles.line}
                                style={{ width: `${x.percent}%` }}
                            ></div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default Balance;
