import React from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import styles from "./WaitingAllowance.module.sass";

const WaitingAllowance = () => {

  const color = "#faca32";
  const items = [
    {
      title: "Status",
      content: "Processing",
      color: color,
    }
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Waiting for allowance!{" "}
          <span role="img" aria-label="hourglass">
            ⏳
          </span>
        </div>
        <div className={styles.info}>
          Waiting for allowance to be processed.
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.loadingDots}>
          <div className={styles.dot}></div>
          &nbsp;
          <div className={styles.dot}></div>
          &nbsp;
          <div className={styles.dot}></div>
        </div>
      </div>
    </>
  );
};

export default WaitingAllowance;
