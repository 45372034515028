import { Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Market from "./screens/Market";
import LearnCrypto from "./screens/LearnCrypto";
import LearnCryptoDetails from "./screens/LearnCryptoDetails";
import Contact from "./screens/Contact";
import Notifications from "./screens/Notifications";
import Activity from "./screens/Activity";
import Exchange from "./screens/Exchange";
import WalletOverview from "./screens/WalletOverview";
import WalletOverviewDetails from "./screens/WalletOverviewDetails";
import WalletMargin from "./screens/WalletMargin";
import FiatAndSpot from "./screens/FiatAndSpot";
import DepositFiat from "./screens/DepositFiat";
import BuyCrypto from "./screens/BuyCrypto";
import SellCrypto from "./screens/SellCrypto";
import ProfileInfo from "./screens/ProfileInfo";
import Referrals from "./screens/Referrals";
import ApiKeys from "./screens/ApiKeys";
import SessionsAndLoginHistory from "./screens/SessionsAndLoginHistory";
import TwoFa from "./screens/TwoFa";
import ChangePassword from "./screens/ChangePassword";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import PageList from "./screens/PageList";
import Withdraw from "./components/Withdraw";
import SubmitBot from "./screens/SubmitBot";

function App() {
    return (
        <RecoilRoot>
            <Routes>
                <Route path="/">
                    <Route
                        index
                        element={
                            <Page headerWide footerHide>
                                <Exchange symbol="rypl" />
                            </Page>
                        }
                    />
                    <Route
                        path="market/:symbol"
                        element={
                            <Page headerWide footerHide>
                                <Exchange />
                            </Page>
                        }
                    />
                </Route>
                <Route
                    path="/upload-bot"
                    element={
                        <Page headerWide footerHide>
                            <SubmitBot />
                        </Page>
                    }
                />
                <Route
                    path="/buy-usdi"
                    element={
                        <Page headerWide footerHide>
                            <BuyCrypto />
                        </Page>
                    }
                /> 
                <Route path="/withdraw">
                    <Route
                        index
                        element={
                            <Page headerWide footerHide>
                                <SellCrypto />
                            </Page>
                        }
                    />
                </Route>
            </Routes>
        </RecoilRoot>
    );
}

export default App;
