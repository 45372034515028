import React, { useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import { URI } from "../../../config/Contracts";
import TextInput from "../../../components/TextInput";
import FileInput from "../../../components/FileInput";

const Form = ({ goFinish }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (selectedFile) => {
    console.log("Archivo seleccionado:", selectedFile);
    setFile(selectedFile);
  };

  const uploadBot = async () => {
    const botName = document.getElementsByName("bot-name")[0].value;
    const botSymbol = document.getElementsByName("bot-symbol")[0].value;

    if (!botName || !botSymbol || !file) {
      console.log("Todos los campos son obligatorios");
      return;
    }

    const formData = new FormData();
    formData.append("bot_name", botName); // Agregamos el bot name al FormData
    formData.append("bot_symbol", botSymbol); // Agregamos el bot symbol al FormData
    formData.append("bot_file", file); // Agregamos el archivo al FormData

    try {
      const response = await fetch(`${URI}/api/bot_manager/upload`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Bot subido exitosamente:", data);
      } else {
        console.error("Error al subir el bot:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  return (
    <form className={styles.form}>
      <div className={cn("h3", styles.title)}>Upload Bot to Test</div>
      <TextInput
        className={styles.field}
        label="Bot Name"
        name="bot-name"
        type="text"
        placeholder="Bot Name"
        required
      />
      <TextInput
        className={styles.field}
        label="Bot Symbol"
        name="bot-symbol"
        type="text"
        placeholder="Bot Symbol"
        required
      />
      <FileInput
                label="Upload .R file"
                className={styles.field}
                classLabel={styles.label}
                onFileChange={handleFileChange}
                name="bot-file"
                required
            />
      <button className={cn("button", styles.button)} onClick={ () => {
              if (document.getElementsByName("bot-name")[0].value === "") {
                console.log("Bot Name is empty")
              } else if (document.getElementsByName("bot-symbol")[0].value === "") {
                console.log("Bot Symbol is empty")
              } else if (document.getElementsByName("bot-file")[0].value === "") {
                console.log("Bot file is empty")
              } else if (window.confirm("Are you sure you want to upload this bot?")) {
                console.log("Bot uploaded")
                uploadBot();
                goFinish();
              }
            }
          }>
        Upload
      </button>
    </form>
  );
};

export default Form;