import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Trades.module.sass";
import { URI } from "../../../config/Contracts";
import { useRecoilValue } from "recoil";
import { symbolState } from "../../../states/symbolState";

const navigation = ["Market trades", "My trades"];

const Trades = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState([]);
    const symbol = useRecoilValue(symbolState);

    // Función para obtener los datos de la API
    const fetchData = async () => {
        try {
            const response = await fetch(`${URI}/api/events?num_events=25&symbol=${symbol}`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            // Formatear los datos para adaptarlos a los items
            const formattedItems = data.map((event) => ({
                event: event.event.substring(0, 11)+"...", // Acortar el nombre del evento
                time: new Date(event.timestamp).toLocaleTimeString(), // Formato de tiempo legible
                price: ((event.data.amount ? event.data.amount : 
                            (event.data.ryptoStableAmount ? event.data.ryptoStableAmount : 
                                (event.data.tokenAmount ? event.data.tokenAmount : event.data.amountSpent))) / 1e18).toFixed(2), // Convertir amount a un formato legible
                color: event.event === "TokensBurned" ? "#FF4D4F" : "#00C076", // Color según el tipo de evento
                transactionHash: event.transactionHash, // Agregar el transactionHash
            }));

            setItems(formattedItems);
        } catch (error) {
            console.error("Error fetching events data:", error);
        }
    };

    useEffect(() => {
        // Llamada inicial a la API
        fetchData();

        // Intervalo para actualizar cada 3 segundos
        const interval = setInterval(fetchData, 3000);
        return () => clearInterval(interval); // Limpiar intervalo al desmontar el componente
    }, [symbol]);

    return (
        <div className={styles.trades}>
            <div className={styles.nav}>
                {navigation.map((x, index) => (
                    <button
                        className={cn(styles.link, {
                            [styles.active]: index === activeIndex,
                        })}
                        onClick={() => setActiveIndex(index)}
                        key={index}
                    >
                        {x}
                    </button>
                ))}
            </div>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>Name</div>
                    <div className={styles.col}>Amount</div>
                    <div className={styles.col}>Time</div>
                </div>
                {items.map((x, index) => (
                    <div className={styles.row} key={index}>
                        <div className={cn(styles.col, styles.truncate)}>
                            <a 
                                href={`https://polygonscan.com/tx/${x.transactionHash}`} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={{ color: x.color }} // Cambiar el color del texto
                            >
                                {x.event}
                            </a>
                        </div>
                        <div className={cn(styles.col, styles.truncate)}>
                            <div style={{ color: x.color }}>{x.price}</div> {/* Aplicamos el color dinámico */}
                        </div>
                        <div className={cn(styles.col, styles.truncate)}>{x.time}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Trades;
