import React, { useState, useEffect } from "react";
import Web3, { LocalWalletNotAvailableError } from 'web3';
import BN from 'bn.js';
import cn from "classnames";
import styles from "./WalletAllowance.module.sass";
import Icon from "../../../components/Icon";
import { usdtContractABI, usdtContractAddress, contractStableAddress, } from "../../../config/Contracts";
import WaitingAllowance from "./WaitingAllowance";
import Modal from "../../../components/Modal";
import Error from "./Error";

const PaymentInfo = ({ goNext, goBack, amount, wallet }) => {
  const [allowanceStatus, setAllowanceStatus] = useState(0);
  const [web3, setWeb3] = useState(null);

  const resetStates = () => {
    setAllowanceStatus(0);
  };

  const processAllowance = async () => {
    if (wallet == "") {
      alert("Please log in!!!");
      return;
    }

    setAllowanceStatus(1);
    const approved = await allowance();

    if (approved) {
      setAllowanceStatus(0)
      goNext();
    } else {
      setAllowanceStatus(-1);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
    }
  }, []);

  const getAdjustedGasPrice = async (web3) => {
    try {
        const gasPrice = await web3.eth.getGasPrice();
        const adjustedGasPrice = (new BN(gasPrice)).mul(new BN(120)).div(new BN(100)); // Aumentar en un 20%
        return adjustedGasPrice.toString(); // Convertir de nuevo a cadena
    } catch (error) {
        console.error("Error getting gas price", error);
        return null;
    }
  };

  const allowance = async () => {
    if (amount <= 0) {
      alert("Price must be greater than 0");
      return false;
    }

    if (web3 && wallet) {
        const usdtContract = new web3.eth.Contract(usdtContractABI, usdtContractAddress);
        try {
            const gasPrice = await getAdjustedGasPrice(web3);
            if (!gasPrice) {
                alert("Unable to get gas price");
                return false;
            }

            const priceInWei = web3.utils.toWei(amount.toString(), 'mwei');
            // Approve the contract to spend USDT on behalf of the user
            await usdtContract.methods.approve(contractStableAddress, priceInWei).send({
                from: wallet,
                gasPrice: gasPrice
            });
        } catch (error) {
            console.error("Error while computing allowance", error);
            return false
        }
        return true;
    }
    return false;
  }

  const options = [
    {
      title: "Spend",
      content: amount + " USDT",
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Get",
      content: amount + " USDI",
      color: "#58BD7D",
      icon: "wallet",
    },
  ];

  return (
    <>
      <div className={styles.control}>
        <button className={styles.back} onClick={(e) => {e.preventDefault(); goBack()}}>
          <Icon name="arrow-prev" size="14" />
          Wallet allowance
        </button>
        <div className={styles.money}>
          Buying Rypto Stable
          <img src="/images/content/currency/usd-coin.svg" alt="Coin" />
        </div>
      </div>
      <div className={styles.options}>
        {options.map((x, index) => (
          <div className={styles.option} key={index}>
            <div className={styles.icon} style={{ backgroundColor: x.color }}>
              <Icon name={x.icon} size="20" />
            </div>
            <div className={styles.details}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={(e) => {e.preventDefault(); processAllowance()}}>
          Allow
        </button>
      </div>
      <Modal
        visible={allowanceStatus == 1}
        onClose={(e) => {e.preventDefault(); setAllowanceStatus(1)}}
      >
        <WaitingAllowance />
      </Modal>
      <Modal
        visible={allowanceStatus == -1}
        onClose={(e) => {e.preventDefault(); resetStates();}}
      >
        <Error />
      </Modal>
    </>
  );
};

export default PaymentInfo;
