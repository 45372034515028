import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import BN from 'bn.js';
import styles from "./Confirm.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Successfully from "./Successfully";
import { buyAmountState } from "../../../states/buyAmountState";
import { withdrawalAccountState } from "../../../states/withdrawalAccountState";
import { purchaseSuccessful } from "../../../states/purchaseSuccessful";
import Processing from "./Processing";
import Error from "./Error";
import Web3 from 'web3';
import { contractStableAddress, contractStableABI, URI } from "../../../config/Contracts";
import { transactionHashState } from "../../../states/transactionHashState";
import { transactionStateState } from "../../../states/transactionStateState";
import { walletState } from "../../../states/walletState";
import { transactionConfirmationsState } from "../../../states/transactionConfirmationsState";


const Confirm = ({ goFinish, goBack }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useRecoilState(purchaseSuccessful);
  const [withdrawAmount, setWithdrawAmount] = useRecoilState(buyAmountState);
  const [withdrawAddress, setWithdrawAddress] = useRecoilState(withdrawalAccountState);
  const [wallet, setWallet] = useRecoilState(walletState);
  const [transactionHash, setTransactionHash] = useRecoilState(transactionHashState);
  const [transactionState, setTransactionState] = useRecoilState(transactionStateState);
  const [transactionConfirmations, setTransactionConfirmations] = useRecoilState(transactionConfirmationsState);
  const [web3, setWeb3] = useState(null);
  const fee = 0.00;

  const resetStates = () => {
    setVisibleSuccessfully(0);
    setTransactionHash(null);
    setTransactionState(0);
  };

  const options = [
    {
      title: "Withdraw",
      content: withdrawAmount + " USDI",
      color: "#141416",
      icon: "wallet",
    },
    {
      title: "Get",
      content: withdrawAmount + " USDT",
      color: "#58BD7D",
      icon: "wallet",
    },
    {
      title: "Network",
      content: "Polygon",
      color: "#8247e5",
      icon: "polygon",
    },
  ];

  useEffect(() => {
    resetStates();
    if (typeof window !== "undefined" && window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
    }
  }, []);
  
  const parameters = [
    {
      title: withdrawAmount,
      content: "USDI",
    },
    {
      title: "Service fee",
      content: (withdrawAmount * fee / 100) + " USDI",
    },
    {
      title: "You will get",
      content: withdrawAmount + " USDT",
    },
  ];

  const getAdjustedGasPrice = async (web3) => {
    try {
        const gasPrice = await web3.eth.getGasPrice();
        const adjustedGasPrice = (new BN(gasPrice)).mul(new BN(120)).div(new BN(100)); // Aumentar en un 20%
        return adjustedGasPrice.toString(); // Convertir de nuevo a cadena
    } catch (error) {
        console.error("Error getting gas price", error);
        return null;
    }
  };

  const processWithdraw = async () => {
    if (web3 && wallet) {
      const contract = new web3.eth.Contract(contractStableABI, contractStableAddress);
      try {
          const gasPrice = await getAdjustedGasPrice(web3);
          if (!gasPrice) {
              alert("Unable to get gas price");
          }

          const priceInWei = web3.utils.toWei(withdrawAmount.toString(), 'ether');
          let confirmationCounter = 0;
          setTransactionConfirmations(confirmationCounter);
          // Swap USDT for RyptoStable
          contract.methods.swapRYPSUSDT(priceInWei).send({
              from: wallet,
              gasPrice: gasPrice
          }).on('transactionHash', (hash) => {
            setTransactionHash(hash);
            setTransactionState(1);
          }).on('receipt', (receipt) => {
            //setTransactionState(2);
          }).on('confirmation', (confirmation) => {
            let receipt = confirmation.receipt;
            if (confirmationCounter == 12) {
              //setVisibleSuccessfully(2);
              console.log("Receipt: " + receipt.transactionHash);
              fetch(`${URI}/api/bot-handler/withdraw`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    transactionHash: receipt.transactionHash
                })
              })
              .then(response => response.json())
              .then(data => {
                  setVisibleSuccessfully(2);
                  console.log("Respuesta de la API:", data);
              })
              .catch(error => {
                  setVisibleSuccessfully(-1);
                  console.error("Error al llamar a la API:", error);
              });
            }
            confirmationCounter++;
            setTransactionConfirmations(confirmationCounter);
          }).on('error', (error) => {
            console.error('Error ejecutando swapUSDT: ', error);
            setVisibleSuccessfully(-1);
          });

          setVisibleSuccessfully(1);
      } catch (error) {
          console.error("Error executing swapUSDT", error);
      }
    }
  }

  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={() => {goBack()}}>
            <Icon name="arrow-prev" size="14" />
            Confirm
          </button>
          <div className={styles.money}>
            Widthdrawing Rypto Stable
            <img src="/images/content/currency/usd-coin.svg" alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.info}>
          You are about to withdraw {withdrawAmount} USDI for {withdrawAmount} USDT on Polygon (POS) network. 
          <br/> to: <span className={styles.walletHighlight}> 
            {withdrawAddress.substring(0, 7) + "..." + withdrawAddress.substring(withdrawAddress.length - 5)}
            </span>
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={(e) => {e.preventDefault(); resetStates(); goFinish()}}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={(e) => {e.preventDefault(); processWithdraw()}}
          >
            I understand, continue
          </button>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully == 1}
        onClose={(e) => {e.preventDefault(); resetStates(); setVisibleSuccessfully(0)}}
      >
        <Processing />
      </Modal>
      <Modal
        visible={visibleSuccessfully == 2}
        onClose={(e) => {e.preventDefault(); resetStates(); setVisibleSuccessfully(0)}}
        >
        <Successfully />
      </Modal>
      <Modal
        visible={visibleSuccessfully == -1}
        onClose={(e) => {e.preventDefault(); resetStates(); setVisibleSuccessfully(0)}}
        >
        <Error />
      </Modal>
    </>
  );
};

export default Confirm;
