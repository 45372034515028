import React from "react";
import cn from "classnames";
import styles from "./FileInput.module.sass";
import Icon from "../Icon";

const FileInput = ({
    className,
    classLabel,
    label,
    note,
    onFileChange, // Prop para manejar el cambio de archivo
    icon, // Prop para mostrar un ícono
    ...props
}) => {
    return (
        <div
            className={cn(styles.field, className)}
        >
            {label && (
                <div className={cn(classLabel, styles.label)}>{label}</div>
            )}
            <div className={styles.wrap}>
                <input
                    type="file"
                    className={styles.input}
                    onChange={(e) => {
                        if (onFileChange) onFileChange(e.target.files[0]);
                    }}
                    {...props}
                />
                {icon && (
                    <div className={styles.preview}>
                        <Icon name={icon} size="24" />
                    </div>
                )}
                {note && <div className={styles.note}>{note}</div>}
            </div>
        </div>
    );
};

export default FileInput;