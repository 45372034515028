import React from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import styles from "./Error.module.sass";
import { Link } from "react-router-dom";
import { transactionHashState } from "../../../../states/transactionHashState";

const price = 1.01;
const color = "#FF6838";

const Error = () => {
  const [transactionHash, setTransactionHash] = useRecoilState(transactionHashState);

  const items = [
    {
      title: "Status",
      content: "Error",
      color: color,
    },
    {
      title: "Transaction ID",
      content: transactionHash,
    },
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Error!{" "}
          <span role="img" aria-label="hourglass">
          ❌
          </span>
        </div>
        <div className={styles.info}>
          There has been an error with your transaction. Please try again.
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Error;
