import React from "react";
import styles from "./Form.module.sass";
import Action from "./Action";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../../components/Icon";
import { useRecoilState } from "recoil";
import { symbolState } from "../../../../states/symbolState";

const Form = ({
    visible,
    setValue,
}) => {
    const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
    const [symbol, setSymbol] = useRecoilState(symbolState);

    return (
        <div className={styles.form}>
            {isTablet ? (
                <>
                    {visible ? (
                        <>
                            <div className={styles.head}>
                                <div className={styles.title}>Place order</div>
                                <button
                                    className={styles.close}
                                    onClick={() => setValue(false)}
                                >
                                    <Icon name="close-circle" size="24" />
                                </button>
                            </div>
                            <Action
                                title={"Buy " + symbol.toUpperCase()}
                                isBuy={true}
                                classButton="button-green"
                                buttonText={"Buy " + symbol.toUpperCase()}
                            />
                        </>
                    ) : (
                        <>
                            <div className={styles.head}>
                                <div className={styles.title}>Place order</div>
                                <button
                                    className={styles.close}
                                    onClick={() => setValue(false)}
                                >
                                    <Icon name="close-circle" size="24" />
                                </button>
                            </div>
                            <Action
                                title={"Sell " + symbol.toUpperCase()}
                                isBuy={false}
                                classButton="button-red"
                                buttonText={"Sell " + symbol.toUpperCase()}
                            />
                        </>
                    )}
                </>
            ) : (
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Action
                            title={"Buy " + symbol.toUpperCase()}
                            isBuy={true}
                            classButton="button-green"
                            buttonText={"Buy" + symbol.toUpperCase()}
                        />
                    </div>
                    <div className={styles.col}>
                        <Action
                            title={"Sell " + symbol.toUpperCase()}
                            isBuy={false}
                            classButton="button-red"
                            buttonText={"Sell " + symbol.toUpperCase()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;
