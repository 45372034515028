import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import styles from "./EnterDetails.module.sass";
import Web3 from 'web3';
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import { buyAmountState } from "../../../states/buyAmountState";
import { withdrawalAccountState } from "../../../states/withdrawalAccountState";
import { walletState } from "../../../states/walletState";
import { contractStableAddress, contractStableABI } from "../../../config/Contracts";

const EnterDetails = ({ goNext, goBack }) => {
  const [web3, setWeb3] = useState(null);
  const [accountInput, setAccountInput] = useRecoilState(withdrawalAccountState);
  const [loggedAddress, setAdress] = useRecoilState(walletState);
  const [amountInput, setAmountInput] = useRecoilState(buyAmountState);
  const [usdiBalance, setUsdiBalance] = useState(0.00);
  const fee = 0.00;

  const fillWithLoggedAccount = () => {
    setAccountInput(loggedAddress);
  };

  const fillWithMaxAmount = () => {
    setAmountInput(usdiBalance);
  };

  // Función para calcular el balance de USDI
  const calculateUsdiBalance = async () => {    
    if (web3 && loggedAddress) {
        const contract = new web3.eth.Contract(contractStableABI, contractStableAddress);
        
        try {
            const balance = await contract.methods.balanceOf(loggedAddress).call();
            setUsdiBalance(web3.utils.fromWei(balance, 'ether'));
        } catch (error) {
            console.error("Error getting balance", error);
        }
    }
  };

  // Inicialización de web3
  useEffect(() => {
    if (typeof window !== "undefined" && window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    }
  }, []); // Solo se ejecuta al montar el componente

  // Cálculo del balance cuando web3 o loggedAddress cambian
  useEffect(() => {
    if (!loggedAddress) {
      alert("Please login to continue");
    }

    if (web3 && loggedAddress) {
      calculateUsdiBalance();
      setAccountInput(loggedAddress);
      const intervalId = setInterval(calculateUsdiBalance, 2000);

      return () => clearInterval(intervalId);
    }
  }, [web3, loggedAddress]); // Ejecuta cuando `web3` o `loggedAddress` cambian

  const processWithdraw = () => {
    if (amountInput > usdiBalance) {
      alert("Insufficient balance");
      return;
    }

    if (amountInput > 0 && accountInput !== "") {
      goNext();
    } else {
      alert("Please fill all fields");
    }
  };

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          Withdrawing USDI
          <img src="/images/content/currency/usd-coin.svg" alt="Coin" />
        </div>
      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="address"
          name="address"
          type="text"
          note="Do not send Tether USD unless you are certain the destination supports
        TRC-20 transactions. If it does not, you could permanently lose access
        to your coins."
          value={loggedAddress}
          readOnly
          //onChange={(e) => setAccountInput(e.target.value)}
        />
        {/* <button 
          className={cn("button-stroke button-small", styles.button)}
          onClick={fillWithLoggedAccount}>
          Logged account
        </button> */}
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          Available <br></br>balance
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>{usdiBalance} USDI</div>
          <div className={styles.price}>{usdiBalance} USDT</div>
        </div>
      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Amount to withdraw"
          name="amount"
          type="text"
          value={amountInput}
          onChange={(e) => setAmountInput(e.target.value)}
        />
        <button 
          className={cn("button-stroke button-small", styles.button)}
          onClick={() => {fillWithMaxAmount();}}
        >
          Max amount
        </button>
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          Transaction Fee <br></br> ({fee}%)
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>{amountInput * fee / 100} USDI</div>
          <div className={styles.price}>{amountInput * fee / 100} USDT</div>
        </div>
      </div>
      <label className={styles.fieldL}>
        <div className={styles.label}>Total</div>
        <input className={styles.inputt} type="text" name="total" required value={amountInput - (amountInput * fee / 100)} readOnly/>
        <div className={styles.currencyy}>{"USDT"}</div>
      </label>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={processWithdraw}>
          Withdraw
        </button>
      </div>
    </>
  );
};

export default EnterDetails;