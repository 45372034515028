import React, { useState } from "react";
import cn from "classnames";
import styles from "./SelectCrypto.module.sass";
import Icon from "../Icon";
import { contractStableAddress } from "../../config/Contracts";

const items = [
  {
    title: "Rypto Stable",
    currency: "USDI",
    price: "$1.00",
    positiveDay: "0.00%",
    image: "/images/content/currency/usd-coin.svg",
    tokenAddress: contractStableAddress,
    tokenDecimals: 18,
    tokenImage: "/images/content/currency/usd-coin.svg",
  },
];

const SelectCrypto = ({ goNext }) => {
  const [search, setSearch] = useState("");

  // Función para agregar el token a MetaMask
  const addTokenToWallet = async (token) => {
    try {
      // Verifica si MetaMask está disponible
      if (window.ethereum) {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: token.tokenAddress,
              symbol: 'USDI',
              decimals: token.tokenDecimals,
              image: window.location.origin + token.tokenImage,
            },
          },
        });

        if (wasAdded) {
          console.log(`${token.currency} fue agregado a MetaMask`);
        } else {
          console.log("Token no agregado");
        }
      } else {
        console.error("MetaMask no está disponible");
      }
    } catch (error) {
      console.error("Error al agregar el token a MetaMask:", error);
    }
  };

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>Select crypto</div>
      <form className={styles.form} action="">
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>24h %</div>
          <div className={styles.col}>Add to Wallet</div>
        </div>
        {items.map((x, index) => (
          <div className={styles.row} key={index} onClick={(e) => {e.preventDefault(); goNext()}}>
            <div className={styles.col}>
              <div className={styles.line}>
                <button className={cn("favorite", styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button>
                {index + 1}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Coin" />
                </div>
                <div className={styles.details}>
                  <span className={styles.subtitle}>{x.title}</span>
                  <span className={styles.currency}>{x.currency}</span>
                </div>
              </div>
            </div>
            <div className={styles.col}>{x.price}</div>
            <div className={styles.col}>
              {x.positiveDay && (
                <div className={styles.positive}>{x.positiveDay}</div>
              )}
              {x.negativeDay && (
                <div className={styles.negative}>{x.negativeDay}</div>
              )}
            </div>
            <div className={styles.col}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={(e) => {
                  e.stopPropagation();
                  addTokenToWallet(x);
                }}
              >
                Add
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCrypto;