import React from 'react';
import styles from './ProgressBar.module.sass';

const ProgressBar = ({ confirmations, total }) => {
  const progress = (confirmations / total) * 100;
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
      <span className={styles.progressText}>
        Confirmations {confirmations} / {total}
      </span>
    </div>
  );
};

export default ProgressBar;