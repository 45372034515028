import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import BN from 'bn.js';
import Web3 from 'web3';
import cn from "classnames";
import styles from "./Action.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../../../../components/Icon";
import { actualPriceState } from "../../../../../states/actualPriceState";
import { contractStableABI, contractStableAddress, 
  botABI, URI } from "../../../../../config/Contracts";
import { walletState } from "../../../../../states/walletState";
import { symbolState } from "../../../../../states/symbolState";
import { addressDictState } from "../../../../../states/addressDictState";

const Action = ({
  title,
  isBuy,
  classButton,
  buttonText,
}) => {
  const [web3, setWeb3] = useState(null);
  const [addressDict, setAddressDict] = useRecoilState(addressDictState);
  const [wallet, setWallet] = useRecoilState(walletState);
  const [values, setValues] = useState([10]);
  const [conversionValue, setConversionValue] = useState(NaN);
  const [price, setActualPrice] = useRecoilState(actualPriceState);
  const [amount, setAmount] = useState("");
  const [usdiBalance, setUsdiBalance] = useState(0);
  const [symbolBalance, setSymbolBalance] = useState(0);
  const [symbol, setSymbol] = useRecoilState(symbolState);

  const stepPrice = 10;
  const minPrice = 0;
  const maxPrice = 100;

  const changeAmount = (e) => {
    const value = parseFloat(e.target.value);
    setAmount(e.target.value);
    calculateValues(value);
    
    if (web3 && wallet) {
      if (isBuy && value > usdiBalance) {
        setAmount(usdiBalance);
      } else if (!isBuy && value > symbolBalance) {
        setAmount(symbolBalance);
      }
    }

    if (isBuy) {
      setConversionValue((amount / price).toFixed(5));
    } else {
      setConversionValue((amount * price).toFixed(5));
    }
    
  }

  const changeSlider = (values) => {
    setValues(values);
    if (isBuy) {
      const newAmount = (usdiBalance * values[0]) / 100;
      setAmount(newAmount.toFixed(5).toString());
      setConversionValue((newAmount / price).toFixed(5));
    } else {
      const newAmount = (symbolBalance * values[0]) / 100;
      setAmount(newAmount.toFixed(5).toString());
      setConversionValue((newAmount * price).toFixed(5));
    }
  }

  const calculateValues = async (value) => {
    if (isBuy) {
      let percetage = (100 * value) / parseFloat(usdiBalance);
      percetage = isNaN(percetage) ? 0 : percetage;
      setValues([Math.min(percetage, 100)]);
    } else {
      let percetage = (100 * value) / parseFloat(symbolBalance);
      percetage = isNaN(percetage) ? 0 : percetage;
      setValues([Math.min(percetage, 100)]);
    }
  }

  useEffect(() => {
    if (amount && price) {
      if (isBuy) {
        setConversionValue((parseFloat(amount) / price).toFixed(5));
      } else {
        setConversionValue((parseFloat(amount) * price).toFixed(5));
      }
    }
  }, [price, amount, isBuy]);

  useEffect(() => {
      if (typeof window !== "undefined" && window.ethereum) {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
      }

      const intervalId = setInterval(calculateBalances, 1000);

      return () => clearInterval(intervalId);
  }, [wallet, price]);

  const getAdjustedGasPrice = async (web3) => {
    try {
        const gasPrice = await web3.eth.getGasPrice();
        const adjustedGasPrice = (new BN(gasPrice)).mul(new BN(120)).div(new BN(100)); // Aumentar en un 20%
        return adjustedGasPrice.toString(); // Convertir de nuevo a cadena
    } catch (error) {
        console.error("Error getting gas price", error);
        return null;
    }
  };

  const calculateBalances = async () => {
    if (!web3 || !wallet) {
        return;
    }

    if (isBuy) {
      calculateUsdiBalance();
    } else {
      calculateSymbolBalance();
    }
  }

  const calculateUsdiBalance = async () => {
    if (web3 && wallet) {
        const contract = new web3.eth.Contract(contractStableABI, contractStableAddress);
        try {
            const balance = await contract.methods.balanceOf(wallet).call();
            setUsdiBalance(web3.utils.fromWei(balance, 'ether'));
        } catch (error) {
            console.error("Error getting balance", error);
        }
    }
  }; 

  const calculateSymbolBalance = async () => {
    if (web3 && wallet) {
        const contract = new web3.eth.Contract(botABI, addressDict[symbol]);
        try {
            const balance = await contract.methods.balanceOf(wallet).call();
            setSymbolBalance(web3.utils.fromWei(balance, 'ether'));
        } catch (error) {
            console.error("Error getting balance", error);
        }
    }
  };

  const handleBuy = async () => {
    if (parseFloat(amount) <= 0) {
        alert("Amount must be greater than 0");
        console.log(amount);
        return;
    }

    if (web3 && wallet) {
      if (parseFloat(amount) > parseFloat(usdiBalance)) {
          alert("Insufficient USDI balance");
          return;
      }
      const contract = new web3.eth.Contract(contractStableABI, contractStableAddress);
      try {
          const gasPrice = await getAdjustedGasPrice(web3);
          if (!gasPrice) {
              alert("Unable to get gas price");
              return;
          }
          const buyAmount = Math.min(parseFloat(amount), parseFloat(usdiBalance));
          await contract.methods.PairTransaction(web3.utils.toWei(buyAmount.toString(), 'ether'), symbol).send({
              from: wallet,
              gasPrice: gasPrice
          }).on('transactionHash', (hash) => {
            fetch(`${URI}/api/bot-handler/purchase`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  transactionHash: hash,
                  symbol: symbol,
              })
            })
            .then(response => response.json())
            .then(data => {
                console.log("Respuesta de la API:", data);
            })
            .catch(error => {
                console.error("Error al llamar a la API:", error);
            });
          })
          .on('receipt', (receipt) => {
              console.log("Transaction was mined:", receipt);
          })
          .on('error', (error) => {
              console.error("Error during the transaction:", error);
          });
          setAmount(0);
      } catch (error) {
          console.error("Error executing PairTransaction", error);
      }
    }
  };
    
  const handleSell = async () => {
    if (parseFloat(amount) <= 0) {
        alert("Price must be greater than 0");
        console.log(amount);
        return;
    }

    if (web3 && wallet) {
      if (parseFloat(amount) > parseFloat(symbolBalance)) {
          alert("Insufficient " + symbol + " balance");
          return;
      }
      
      const contract = new web3.eth.Contract(botABI, addressDict[symbol]);
      try {
          const gasPrice = await getAdjustedGasPrice(web3);
          if (!gasPrice) {
              alert("Unable to get gas price");
              return;
          }
          
          const sellAmount = Math.min(parseFloat(amount), parseFloat(symbolBalance));
          await contract.methods.burn(web3.utils.toWei(sellAmount.toString(), 'ether')).send({
            from: wallet,
            gasPrice: gasPrice
          }).on('transactionHash', (hash) => {
            fetch(`${URI}/api/bot-handler/sell`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  transactionHash: hash,
                  symbol: symbol,
              })
            })
            .then(response => response.json())
            .then(data => {
                console.log("Respuesta de la API:", data);
            })
            .catch(error => {
                console.error("Error al llamar a la API:", error);
            });
          })
          .on('receipt', (receipt) => {
          })
          .on('error', (error) => {
              console.error("Error during the transaction:", error);
          });
          setAmount(0);
      } catch (error) {
          console.error("Error executing burn", error);
      }
    } else {
        console.log("error en " + symbol + "!")
    }
  };

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter}>
          <Icon name="wallet" size="16" /> {isBuy ? usdiBalance + " USDI" : symbolBalance + " " + symbol.toUpperCase()}
        </div>
      </div>
      <label className={styles.field}>
        <div className={styles.label}>Amount</div>
        <input className={styles.input} type="text" name="amount" required value={isNaN(amount) ? "" : amount}
        onChange={(e) => changeAmount(e)} />
        <div className={styles.currency}>{isBuy ? "USDI": symbol.toUpperCase()}</div>
      </label>
      <Range
        values={values}
        step={stepPrice}
        min={minPrice}
        max={maxPrice}
        onChange={(values) => changeSlider(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "6px",
              width: "2px",
              marginTop: "-2px",
              borderRadius: "1px",
              backgroundColor:
                index * stepPrice < values[0] ? "#3772FF" : "#E6E8EC",
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "1px",
                background: getTrackBackground({
                  values,
                  colors: ["#3772FF", "#E6E8EC"],
                  min: minPrice,
                  max: maxPrice,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              backgroundColor: "#F4F5F6",
              border: "4px solid #777E90",
              boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-27px",
                color: "#FCFCFD",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                fontFamily: "Poppins",
                padding: "2px 6px",
                borderRadius: "6px",
                backgroundColor: "#777E90",
              }}
            >
              {values[0].toFixed(0)}%
            </div>
          </div>
        )}
      />
      <label className={styles.field}>
        <div className={styles.label}>Total</div>
        <input className={styles.input} type="text" name="total" required value={isNaN(conversionValue) ? "" : conversionValue} readOnly/>
        <div className={styles.currency}>{isBuy ? symbol.toUpperCase() : "USDI"}</div>
      </label>
      <button className={cn(classButton, styles.button)}
        onClick={isBuy ? handleBuy : handleSell}
      >{buttonText}</button>
    </>
  );
};

export default Action;
