import React, { useState } from "react";
import { useRecoilState } from "recoil"; // Importar el hook de Recoil
import { buyAmountState } from "../../states/buyAmountState"; // Importar el estado de Recoil
import Bidding from "../../components/Bidding";
import SelectCrypto from "../../components/SelectCrypto";
import EnterAmount from "./EnterAmount";
import PaymentInfo from "./WalletAllowance";
import ConfirmOrder from "./ConfirmOrder";
import { walletState } from "../../states/walletState";

const steps = [
  "Select crypto",
  "Enter amount",
  "Wallet allowance",
  "Confirm order",
];

const BuyCrypto = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [buyAmount, setBuyAmount] = useRecoilState(buyAmountState);
  const [wallet, setWallet] = useRecoilState(walletState);

  // Función para actualizar la cantidad de monedas a comprar
  const handleAmountChange = (amount) => {
    setBuyAmount(amount);
  };

  return (
    <>
      <Bidding title="Buy crypto" items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && <SelectCrypto goNext={() => setActiveIndex(1)} />}
        {activeIndex === 1 && (
          <EnterAmount
            goBack={() => setActiveIndex(0)}
            goNext={() => setActiveIndex(2)}
            onAmountChange={handleAmountChange}
          />
        )}
        {activeIndex === 2 && (
          <PaymentInfo
            goBack={() => setActiveIndex(1)}
            goNext={() => setActiveIndex(3)}
            amount={buyAmount}
            wallet={wallet}
          />
        )}
        {activeIndex === 3 && (
          <ConfirmOrder
            goBack={() => setActiveIndex(2)}
            goStart={() => setActiveIndex(0)}
            amount={buyAmount}
            wallet={wallet}
          />
        )}
      </Bidding>
    </>
  );
};

export default BuyCrypto;
