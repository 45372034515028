import React, { useState, useEffect } from "react";
import Web3 from 'web3';
import BN from 'bn.js';
import cn from "classnames";
import styles from "./ConfirmOrder.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Successfully from "./Successfully";
import Processing from "./Processing";
import PurchaseError from "./Error";
import { useRecoilState } from "recoil";
import { purchaseSuccessful } from "../../../states/purchaseSuccessful";
import { transactionHashState } from "../../../states/transactionHashState";
import { transactionStateState } from "../../../states/transactionStateState";
import { transactionConfirmationsState } from "../../../states/transactionConfirmationsState";
import { contractStableAddress, contractStableABI, URI } from "../../../config/Contracts";

const fee = 0.00

const ConfirmOrder = ({ goBack, goStart, amount, wallet }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useRecoilState(purchaseSuccessful);
  const [transactionHash, setTransactionHash] = useRecoilState(transactionHashState);
  const [transactionState, setTransactionState] = useRecoilState(transactionStateState);
  const [transactionConfirmations, setTransactionConfirmations] = useRecoilState(transactionConfirmationsState);
  const [web3, setWeb3] = useState(null);
  
  const options = [
    {
      title: "Spend",
      content: amount + " USDT",
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Get",
      content: amount + " USDI",
      color: "#58BD7D",
      icon: "wallet",
    },
    {
      title: "Method",
      content: "Wallet Payment",
      color: "#4BC9F0",
      icon: "wallet",
    },
  ];
  
  const parameters = [
    {
      title: amount,
      content: "USDI",
    },
    {
      title: "Service fee",
      content: fee + " USDI",
    },
    {
      title: "You will pay",
      content: (amount + fee) + " USDT",
    },
  ];

  const resetStates = () => {
    setVisibleSuccessfully(0);
    setTransactionHash(null);
    setTransactionState(0);
  };

  useEffect(() => {
    resetStates();
    if (typeof window !== "undefined" && window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
    }
  }, []);

  const getAdjustedGasPrice = async (web3) => {
    try {
        const gasPrice = await web3.eth.getGasPrice();
        const adjustedGasPrice = (new BN(gasPrice)).mul(new BN(120)).div(new BN(100)); // Aumentar en un 20%
        return adjustedGasPrice.toString(); // Convertir de nuevo a cadena
    } catch (error) {
        console.error("Error getting gas price", error);
        return null;
    }
  };

  const handleConfirm = async () => {
    if (wallet == "") {
        alert("Please connect your wallet");
        return;
    }

    await handleBuy();
  };

  const handleBuy = async () => {
    if (amount <= 0) {
        alert("Price must be greater than 0");
    }

    if (web3 && wallet) {
        const contract = new web3.eth.Contract(contractStableABI, contractStableAddress);
        try {
            const gasPrice = await getAdjustedGasPrice(web3);
            if (!gasPrice) {
                alert("Unable to get gas price");
            }

            const priceInWei = web3.utils.toWei(amount.toString(), 'mwei'); // Convertir el precio a USDT con 6 decimales
            let confirmationCounter = 0;
            setTransactionConfirmations(confirmationCounter);
            // Swap USDT for RyptoStable
            contract.methods.swapUSDTRYPS(priceInWei).send({
                from: wallet,
                gasPrice: gasPrice
            }).on('transactionHash', (hash) => {
              setTransactionHash(hash);
              setTransactionState(1);
            }).on('receipt', (receipt) => {
              setTransactionState(2);
            }).on('confirmation', (transaction) => {
              let receipt = transaction.receipt
              if (confirmationCounter == 12) {
                setVisibleSuccessfully(2);
                console.log("Receipt: " + receipt.transactionHash);
                fetch(`${URI}/api/bot-handler/notify-event`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      transactionHash: receipt.transactionHash
                  })
                })
                .then(response => response.json())
                .then(data => {
                    console.log("Respuesta de la API:", data);
                })
                .catch(error => {
                    console.error("Error al llamar a la API:", error);
                });
              }
              confirmationCounter++;
              setTransactionConfirmations(confirmationCounter);
            }).on('error', (error) => {
              console.error('Error ejecutando swapUSDT: ', error);
              setVisibleSuccessfully(-1);
            });

            setVisibleSuccessfully(1);
        } catch (error) {
            console.error("Error executing swapUSDT", error);
        }
    }
  };

  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={(e) => {e.preventDefault(); goBack()}}>
            <Icon name="arrow-prev" size="14" />
            Confirm order
          </button>
          <div className={styles.money}>
            Buying Rypto Stable
            <img src="/images/content/currency/usd-coin.svg" alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.info}>
            You are about to buy USDI from Rypto to your wallet 
            <span className={styles.walletHighlight}> {wallet.substring(0, 7) + "..." + wallet.substring(wallet.length - 5)}</span>
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={(e) => {e.preventDefault(); goStart(); resetStates();}}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={(e) => { e.preventDefault(); handleConfirm()}}
          >
            I understand, continue
          </button>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully == 1}
        onClose={(e) => {e.preventDefault(); resetStates();}}
      >
        <Processing />
      </Modal>
      <Modal
        visible={visibleSuccessfully == 2}
        onClose={(e) => {e.preventDefault(); resetStates()}}
      >
        <Successfully 
          resetStates={(e) => {e.preventDefault(); resetStates(); goStart()}}
        />
      </Modal>
      <Modal
        visible={visibleSuccessfully == -1}
        onClose={(e) => {e.preventDefault(); resetStates(); goStart()}}
      >
        <PurchaseError />
      </Modal>
    </>
  );
};

export default ConfirmOrder;
