import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";


const User = ({ className, account, onLogout }) => {
  const [visible, setVisible] = useState(false);
  
  const items = [
    {
      title: "Connected account",
      icon: "wallet",
      content: account.substr(0, 6) + "..." + account.substr(-4),
    },
    {
      title: "Profile",
      icon: "user",
      content: "Important account details",
      //url: "/profile-info",
    },
    {
      title: "Referrals",
      icon: "share",
      content: "Invite your friends and earn rewards",
      //url: "/referrals",
    },
    {
      title: "Settings",
      icon: "cog",
      content: "View additional settings",
      //url: "/api-keys",
    },
    {
      title: "Dark mode",
      icon: "theme-dark",
      content: "Switch dark/light mode",
      switch: true,
    },
  ];
  
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src="/images/content/avatar-user.jpg" alt="Avatar" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : x.switch ? (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                      <Theme className={styles.theme} small />
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              ) : (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              )
            )}
            {/* Logout option */}
            <div
              className={styles.item}
              onClick={() => {
                setVisible(false);
                onLogout();
              }}
            >
              <div className={styles.icon}>
                <Icon name="exit" size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Log out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
