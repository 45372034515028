import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";

const Proflie = ({ title, breadcrumbs, children }) => {
    return (
        <div className={styles.profile}>
            <div className={styles.head}>
                <div className={cn("container", styles.container)}>
                    <h2 className={cn("h2", styles.title)}>{title}</h2>
                </div>
            </div>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.wrapper}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Proflie;
