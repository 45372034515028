import React from "react";
import cn from "classnames";
import styles from "./Error.module.sass";

const color = "#FF6838";

const PurchaseError = () => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Error!{" "}
          <span role="img" aria-label="hourglass">
          ❌
          </span>
        </div>
        <div className={styles.info}>
          There has been an error while processing the allowance. Please try again.
        </div>
      </div>
    </>
  );
};

export default PurchaseError;
