import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil"; // Importar hook de Recoil
import { walletState } from "../../states/walletState"; // Importar el estado global de la wallet
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Icon from "../Icon";
import Theme from "../Theme";
import Notifications from "./Notifications";
import User from "./User";

const navigation = [
    {
        title: "Exchange",
        url: "/"
    },
    {
        title: "Buy USDI",
        url: "/buy-usdi",
    },
    {
        title: "Withdraw",
        url: "/withdraw",
    },
    {
        title: "Discover",
    },
];

const Header = ({ headerWide }) => {
    const [visibleNav, setVisibleNav] = useState(false);
    const { pathname } = useLocation();
    const [account, setAccount] = useRecoilState(walletState); // Usar Recoil state para manejar el address conectado

    // Función para conectar con MetaMask
    const connectToMetaMask = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setAccount(accounts[0]); // Guardar la cuenta en el estado global de Recoil
                localStorage.setItem("userAccount", accounts[0]); // Guardar la cuenta en localStorage
                console.log("Connected account:", accounts[0]);
            } catch (error) {
                console.error("Error connecting to MetaMask:", error);
            }
        } else {
            alert("MetaMask is not installed. Please install it to use this feature.");
        }
    };

    // Restaurar la cuenta guardada en localStorage al cargar la página
    useEffect(() => {
        const savedAccount = localStorage.getItem("userAccount");
        if (savedAccount) {
            setAccount(savedAccount); // Restaurar la cuenta guardada en localStorage
        }
    }, []);

    // Función para hacer logout
    const handleLogout = () => {
        setAccount("");
        localStorage.removeItem("userAccount"); // Limpiar la cuenta de localStorage
        console.log("User logged out");
    };

    return (
        <header className={cn(styles.header, { [styles.wide]: headerWide })}>
            <div className={cn("container", styles.container)}>
                <Link
                    className={styles.logo}
                    to="/"
                    onClick={() => setVisibleNav(false)}
                >
                    <Image
                        className={styles.picDesktop}
                        src="/images/logo-light.png"
                        srcDark="/images/logo-dark.png"
                        alt="Rypto"
                    />
                    <img
                        className={styles.picMobile}
                        src="/images/logo.png"
                        alt="Rypto"
                    />
                </Link>
                <div className={styles.wrapper}>
                    <div
                        className={cn(styles.wrap, {
                            [styles.visible]: visibleNav,
                        })}
                    >
                        <nav className={styles.nav}>
                            {navigation.map((x, index) =>
                                x.dropdown ? (
                                    <Dropdown
                                        className={styles.dropdown}
                                        key={index}
                                        item={x}
                                        setValue={setVisibleNav}
                                    />
                                ) : (
                                    <NavLink
                                        className={cn(styles.item, {
                                            [styles.active]: pathname === x.url,
                                        })}
                                        onClick={() => setVisibleNav(false)}
                                        to={x.url}
                                        key={index}
                                    >
                                        {x.title}
                                    </NavLink>
                                )
                            )}
                        </nav>
                        <NavLink
                            className={cn("button-stroke", styles.button, {
                                [styles.active]:
                                    pathname === "/wallet-overview",
                            })}
                            onClick={() => setVisibleNav(false)}
                        >
                            Wallet
                        </NavLink>
                    </div>
                    <Settings className={styles.settings} />
                    <Theme className={styles.theme} icon />
                    <NavLink
                        className={cn(styles.activity, {
                            [styles.active]: pathname === "/activity",
                        })}
                    >
                        <Icon name="lightning" size="24" />
                    </NavLink>
                    <div className={styles.control}>
                        <Notifications className={styles.notifications} />
                        
                        {/* Si la cuenta está conectada, mostrar User; si no, mostrar el botón Login */}
                        {account ? (
                            <User className={styles.user} account={account} onLogout={handleLogout} />
                        ) : (
                            <button
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button,
                                    {
                                        [styles.active]:
                                            pathname === "/wallet-overview",
                                    }
                                )}
                                onClick={connectToMetaMask}
                            >
                                Login
                            </button>
                        )}
                    </div>
                    <button
                        className={cn(styles.burger, {
                            [styles.active]: visibleNav,
                        })}
                        onClick={() => setVisibleNav(!visibleNav)}
                    ></button>
                </div>
            </div>
        </header>
    );
};

export default Header;
