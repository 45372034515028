import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../../../components/Icon";
import { useRecoilState } from "recoil"; // Importar hook de Recoil
import { actualPriceState } from "../../../states/actualPriceState";
import { priceColorState } from "../../../states/priceColorState";
import { URI } from "../../../config/Contracts";
import { symbolState } from "../../../states/symbolState";

const baseItems = [
    {
        title: "24h change",
        content: "Cargando...",
        icon: "clock",
        color: "#00C076", // Color por defecto
    },
    {
        title: "24h high",
        content: "Cargando...",
        icon: "arrow-top",
    },
    {
        title: "24h low",
        content: "Cargando...",
        icon: "arrow-bottom",
    },
    {
        title: "24h volume",
        content: "Cargando...",
        icon: "chart",
    },
]

const Main = () => {

    const [actualPrice, setActualPrice] = useRecoilState(actualPriceState); // Usar Recoil state para manejar el precio actual
    const [color, setColor] = useRecoilState(priceColorState); // Color por defecto
    const [symbol, setSymbol] = useRecoilState(symbolState);

    const [items, setItems] = useState(baseItems);

    const fetchData = async () => {
        if (!symbol) {
            return;
        }
        try {
            const response = await fetch(`${URI}/api/header-meta/candle-stats/${symbol}`);
            const data = await response.json();
            
            const previousPrice = parseFloat(data.openPrice);
            const currentPrice = parseFloat(data.lastPrice);
            const priceChangeColor = parseFloat(data.priceChange) >= 0 ? "#00C076" : "#FF4D4F";
            // Actualizamos otros valores de la lista
            setItems([
                {
                    title: "24h change",
                    content: `${(parseFloat(data.priceChange)).toFixed(6)}`,
                    icon: "clock",
                    color: priceChangeColor,
                },
                {
                    title: "24h % change",
                    content: `${(parseFloat(data.priceChangePercent)).toFixed(3)}%`,
                    icon: "clock",
                    color: priceChangeColor,
                },
                {
                    title: "24h high",
                    content: `${parseFloat(data.highPrice).toFixed(4)}`,
                    icon: "arrow-top",
                },
                {
                    title: "24h low",
                    content: `${parseFloat(data.lowPrice).toFixed(4)}`,
                    icon: "arrow-bottom",
                },
                // {
                //     title: "24h volume",
                //     content: `${parseFloat(data.volume).toFixed(6)}`,
                //     icon: "chart",
                // },
                // {
                //     title: "24h usdt volume",
                //     content: `${parseFloat(data.quoteVolume).toFixed(6)}`,
                //     icon: "chart",
                // }
            ]);
        } catch (error) {
            console.error("Error fetching the data:", error);
        }
    };

    useEffect(() => {
        setItems(baseItems);
        fetchData();
        // Llamar a la API cada 3 segundos
        const interval = setInterval(fetchData, 3000);
        return () => clearInterval(interval);
    }, [color, symbol]);

    return (
        <div className={styles.main}>
            <div className={styles.details}>
                <div className={styles.box}>
                    <div className={styles.line}>
                        <div className={styles.info}>{symbol.toUpperCase()}/USDI</div>
                        <div className={cn("category-green", styles.category)}>
                            1x
                        </div>
                    </div>
                    <div className={styles.content}>{symbol.toUpperCase()}</div>
                </div>
                <div className={styles.box}>
                    <div className={styles.price} style={{ color: color }}>
                        {actualPrice.toFixed(7)}
                    </div>
                    <div className={styles.content}>
                        <Icon name="coin" size="16" /> {actualPrice}
                    </div>
                </div>
            </div>
            <div className={styles.list}>
                {items.map((x, index) => (
                    <div className={styles.item} key={index}>
                        <div className={styles.subtitle}>
                            <Icon name={x.icon} size="16" />
                            {x.title}
                        </div>
                        <div
                            className={styles.content}
                            style={{ color: x.color }}
                        >
                            {x.content}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Main;
