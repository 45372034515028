import React, { useState } from "react";
import cn from "classnames";
import styles from "./EnterAmount.module.sass";
import Icon from "../../../components/Icon";
import { useRecoilState } from "recoil"; // Importar el hook de Recoil
import { buyAmountState } from "../../../states/buyAmountState"; // Importar el estado de Recoil

const priceVariants = ["1.00", "5.00", "10.00"];
//const [buyAmountState, setAmount] = useRecoilState(buyAmountState);

const EnterAmount = ({ goNext, goBack, onAmountChange }) => {
  const [price, setPrice] = useState("5.00");

  const next = () => {
    onAmountChange(price);
    goNext();
  }

  return (
    <form className={styles.item} action="">
      <div className={styles.control}>
        <button className={styles.back} onClick={(e) => {e.preventDefault(); goBack()}}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          Buying Rypto Stable
          <img src="/images/content/currency/usd-coin.svg" alt="Coin" />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className={cn("h4", styles.sign)}>USDT</div>
      </div>
      <div className={styles.price}>
        {parseInt(price)} <span>USDI</span>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            key={index}
            onClick={() => setPrice(x)}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={(e) => { e.preventDefault(); next()}}>
          Continue
        </button>
      </div>
    </form>
  );
};

export default EnterAmount;
