import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import { debounce } from 'lodash';
import { createChart, CrosshairMode } from 'lightweight-charts';
import styles from "./Charts.module.sass";
import useDarkMode from "use-dark-mode";
import { URI } from "../../../config/Contracts";
import { actualPriceState } from "../../../states/actualPriceState";
import { priceColorState } from "../../../states/priceColorState";
import { actualOpenPriceState } from "../../../states/actualOpenPriceState";
import { symbolState } from "../../../states/symbolState";
import { useParams } from "react-router-dom";

const intervals = {
    "Time": "1m",   
    "15M": "15m",
    "30M": "30m",
    "1H": "1h",
    "4H": "4h",
    "12H": "12h",
    "1D": "1d",
};

const intervalValues = {
    "1m": 1,   
    "15m": 15,
    "30m": 30,
    "1h": 60,
    "4h": 240,
    "12h": 720,
    "1d": 1440,
};

const Actions = () => {
    const [activeIndexDates, setActiveIndexDates] = useState(0);
    const [candleInterval, setCandleInterval] = useState('1m'); 
    const darkMode = useDarkMode(true);
    const chartContainerRef = useRef(null);
    const candleSeriesRef = useRef(null); 
    const chartRef = useRef(null); 
    const [symbol, setSymbol] = useRecoilState(symbolState);
    const [hoveredCandle, setHoveredCandle] = useState(null); 
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); 
    const [actualPrice, setActualPrice] = useRecoilState(actualPriceState); 
    const [priceColor, setPriceColor] = useRecoilState(priceColorState); 
    const [actualOpenPrice, setActualOpenPrice] = useRecoilState(actualOpenPriceState); 
    const [lastCandleTime, setLastCandleTime] = useState(0);
    const { paramSymbol } = useParams();

    const handleVisibleRangeChange = (newVisibleRange) => {
        if (candleSeriesRef.current && candleSeriesRef.current.data()) {
            const firstCandleTime = candleSeriesRef.current.data()[0].time;
            
            const localOffset = new Date(firstCandleTime * 1000).getTimezoneOffset();
            const utcFirstCandleTime = (firstCandleTime + (localOffset * 60));
            
            // Obtener el borde izquierdo del gráfico visible
            const visibleFrom = chartRef.current.timeScale().getVisibleRange().from;
    
            // Cargar velas si el usuario retrocede más allá de la primera vela visible
            if (visibleFrom == firstCandleTime) {
                const newFrom = visibleFrom - (intervalValues[candleInterval] * 50 * 60 * 100)
                fetchHistoricalData(newFrom, utcFirstCandleTime, true); // Cargar velas históricas
            }
        }
    };    

    const convert_date = (date) => {
        const utcDate = new Date(date + "Z"); 
        const localTimeOffset = utcDate.getTimezoneOffset() * 60; 
        return Math.floor(utcDate.getTime() / 1000) - localTimeOffset;
    };

    const handleVisibleRangeChangeDebounced = debounce(handleVisibleRangeChange, 100);

    const fetchHistoricalData = async (from = null, to = null, append = false) => {
        if (!symbol) {
            return;
        }
        try {
            const from_string = from ? `&from_time_str=${from}` : '';
            const to_string = to ? `&to_time_str=${to}` : '';
            const response = await fetch(`${URI}/api/candles?bot_name=${symbol}&interval=${candleInterval}${from_string}${to_string}`);
            if (!response.ok) {
                throw new Error(`Error HTTP! status: ${response.status}`);
            }
            const historicalData = await response.json();

            if (!historicalData || historicalData.length === 0) {
                return;
            }
    
            const formattedData = historicalData.map(candle => ({
                time: convert_date(candle.datetime),
                open: candle.open,
                high: candle.high,
                low: candle.low,
                close: candle.close,
            }));
    
            const pos = formattedData.length - 1;
            setActualPrice(formattedData[pos].close);
            setPriceColor(formattedData[pos].close >= formattedData[pos].open ? "#00C076" : "#FF4D4F");
    
            if (append) {
                // Obtener los datos actuales de la serie
                const currentData = candleSeriesRef.current.data();
                
                // Filtrar los datos nuevos que ya existan en la serie actual
                const newData = formattedData.filter(newCandle => 
                    !currentData.some(existingCandle => existingCandle.time === newCandle.time)
                );
                
                // Asegurarse de que los datos se mantengan ordenados
                const combinedData = [...newData, ...currentData].sort((a, b) => a.time - b.time);
                
                candleSeriesRef.current.setData(combinedData);
            } else {
                candleSeriesRef.current.setData(formattedData);
    
                if (formattedData.length > 0) {
                    chartRef.current.timeScale().fitContent();
                }
            }
        } catch (error) {
            console.error("Error fetching historical data:", error);
        }
    };

    const fetchLastCandle = async () => {
        if (!symbol) {
            return;
        }
        try {
            const response = await fetch(`${URI}/api/candles/last?bot_name=${symbol}&interval=${candleInterval}`);
            if (!response.ok) {
                throw new Error(`Error HTTP! status: ${response.status}`);
            }

            const lastCandle = await response.json();

            if (!lastCandle) {
                return;
            }

            const formattedData = lastCandle.map(candle => ({
                time: convert_date(candle.datetime),
                open: candle.open,
                high: candle.high,
                low: candle.low,
                close: candle.close,
            }));

            // if (lastCandleTime && lastCandleTime !== formattedData[1].time) {
            //     candleSeriesRef.current.update(formattedData[0]);
            // }

            candleSeriesRef.current.update(formattedData[1]);
            setActualPrice(formattedData[1].close);
            setActualOpenPrice(formattedData[1].open);
            setPriceColor(formattedData[1].close >= formattedData[1].open ? "#00C076" : "#FF4D4F");
            setLastCandleTime(formattedData[1].time);
        } catch (error) {
            console.error("Error fetching last candle:", error);
        }
    };

    const handleDateChange = (index, intervalKey) => {
        setActiveIndexDates(index);
        setCandleInterval(intervals[intervalKey]);
    };

    useEffect(() => {
        if (!chartContainerRef.current) return;
    
        if (chartRef.current) {
            chartRef.current.remove();
            chartRef.current = null;
        }
    
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 450,
            timeScale: { timeVisible: true, secondsVisible: false },
            layout: {
                background: {
                    color: darkMode.value ? '#121212' : '#ffffff',  
                },
                textColor: darkMode.value ? '#DDD' : '#000',  
            },
            grid: {
                vertLines: { color: darkMode.value ? '#444' : '#e0e0e0' },  
                horzLines: { color: darkMode.value ? '#444' : '#e0e0e0' },
            },
            rightPriceScale: { 
                scaleMargins: { top: 0.2, bottom: 0.2 },
                borderColor: darkMode.value ? '#444' : '#e0e0e0'  
            },
            crosshair: { mode: CrosshairMode.Normal },
            barSpacing: 5,
        });
    
        chartRef.current = chart;
        const candleSeries = chart.addCandlestickSeries();
        candleSeriesRef.current = candleSeries;
    
        if (candleSeriesRef.current._data && candleSeriesRef.current._data.length > 0) {
            candleSeriesRef.current.setData(candleSeriesRef.current._data);  
        } else {
            fetchHistoricalData();  
            chart.timeScale().fitContent();
        }
    
        chart.subscribeCrosshairMove((param) => {
            if (!param || !param.point || !param.seriesData.get(candleSeries)) {
                setHoveredCandle(null);  
                return;
            }
    
            const hoveredData = param.seriesData.get(candleSeries);
            if (hoveredData) {
                const screenWidth = window.innerWidth;
                const offset = 15;
                let tooltipX = param.point.x + offset;
    
                if (param.point.x + 200 > screenWidth) {
                    tooltipX = param.point.x - offset - 200;
                }
    
                setHoveredCandle(hoveredData);
                setTooltipPosition({ x: tooltipX, y: param.point.y });
            } else {
                setHoveredCandle(null);
            }
        });

        return () => {
            chart.unsubscribeCrosshairMove();
        };
    }, [darkMode.value, symbol, candleInterval]);  

    useEffect(() => {
        if (symbol == "") {
            if (!paramSymbol) {
                setSymbol('rypl')
            } else {
                setSymbol(paramSymbol);
            }
        }

        if (chartRef.current) {
            fetchHistoricalData();
            const intervalId = setInterval(fetchLastCandle, 3000);
            chartRef.current.timeScale().subscribeVisibleTimeRangeChange(handleVisibleRangeChangeDebounced);
            return () => {
                clearInterval(intervalId);
                chartRef.current.timeScale().unsubscribeVisibleTimeRangeChange(handleVisibleRangeChangeDebounced);
            };
        }
    }, [symbol, candleInterval]);

    return (
        <div className={styles.charts}>
            <div className={styles.head}>
                <div className={styles.group}>
                    <div className={styles.nav}>
                        {Object.keys(intervals).map((x, index) => (
                            <button className={cn(styles.link, { [styles.active]: index === activeIndexDates })}
                                    onClick={() => handleDateChange(index, x)} key={index}>
                                {x}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div ref={chartContainerRef} style={{ width: '100%', height: '450px' }}></div>

            {hoveredCandle && (
                <div className={styles.hoveredCandle} style={{
                    top: tooltipPosition.y + 15, left: tooltipPosition.x + 15, position: 'absolute',
                    backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '4px',
                    zIndex: 1000, pointerEvents: 'none', whiteSpace: "nowrap", 
                }}>
                    <div>Open: {hoveredCandle.open}</div>
                    <div>High: {hoveredCandle.high}</div>
                    <div>Low: {hoveredCandle.low}</div>
                    <div>Close: {hoveredCandle.close}</div>
                </div>
            )}
        </div>
    );
};

export default Actions;