import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import cn from "classnames";
import BN from 'bn.js';
import Web3 from 'web3';
import styles from "./Actions.module.sass";
import Icon from "../../../components/Icon";
import Form from "./Form";
import { walletState } from "../../../states/walletState";

const navigation = ["Market"];

const Actions = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [priceStable, setPriceStable] = useState(0);
  const [wallet, setWallet] = useRecoilState(walletState);

  const handleClickBuy = () => {
    setVisibleAction(true);
    setVisible(true);
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
    }
  }, []);

  const handleClickSell = () => {
    setVisibleAction(false);
    setVisible(true);
  };

  return (
    <div className={styles.actions}>
      <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div className={styles.info}>
          Crypto trading tutorial
          <Link>
            Learn now <Icon name="arrow-right" size="20" />
          </Link>
        </div>
      </div>
      <div className={cn(styles.wrapper, { [styles.show]: visible })}>
        {activeIndex === 0 && (
          <Form
            price={priceStable}
            visible={visibleAction}
            setValue={setVisible}
          />
        )}
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button-green button-small", styles.button)}
          onClick={() => handleClickBuy()}
        >
          Buy
        </button>
        <button
          className={cn("button-red button-small", styles.button)}
          onClick={() => handleClickSell()}
        >
          Sell
        </button>
      </div>
    </div>
  );
};

export default Actions;
