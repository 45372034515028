import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { URI } from "../../../config/Contracts";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { symbolState } from "../../../states/symbolState";
import { addressDictState } from "../../../states/addressDictState";

const navigation = ["All", "Cross", "Isolated"];

const Currency = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [addressDict, setAddressDict] = useRecoilState(addressDictState);
  const [search, setSearch] = useState("");
  const symbol = useRecoilValue(symbolState);
  const [items, setItems] = useState([
    {
      title: symbol.toUpperCase(),
      currency: symbol.toUpperCase(),
      lastPrice: "0.01052",
      priceChange: 0,
      tokenAddress: "",
      tokenDecimals: 18,
      tokenImage: "",
    },
  ]);
  
  const addTokenToWallet = async (index) => {
    try {
      // Verifica si MetaMask está disponible
      if (window.ethereum) {
        const token = items[index];
        console.log("address", token.tokenAddress);
        console.log("symbol", token.title);
        console.log("decimals", token.tokenDecimals);
        console.log("image", window.location.origin + token.tokenImage);
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: token.tokenAddress,
              symbol: token.title,
              decimals: token.tokenDecimals,
              image: window.location.origin + token.tokenImage,
            },
          },
        });
  
        if (wasAdded) {
          console.log(`${token.currency} fue agregado a MetaMask`);
        } else {
          console.log("Token no agregado");
        }
      } else {
        console.error("MetaMask no está disponible");
      }
    } catch (error) {
      console.error("Error al agregar el token a MetaMask:", error);
    }
  };

  // Función para obtener los datos desde el endpoint
  const fetchData = async () => {
    try {
      const response = await fetch(`${URI}/api/bot_overview`);
      const data = await response.json();

      // Formatear los datos obtenidos para usarlos en los items
      const formattedItems = data.map((item) => ({
        title: `${item.bot_symbol.toUpperCase()}`, // Asumiendo que es un par con USDT
        name: `${item.bot_name.toUpperCase()}`,
        currency: "USDT",
        lastPrice: item.last_price.toFixed(2),
        priceChange: item.percentage_change.toFixed(2), // Usamos el porcentaje de cambio para mostrar
        tokenAddress: item.bot_address,
        tokenDecimals: 18,
        tokenImage: "/images/content/currency/" + item.bot_name + ".svg",
      }));
      
      const newAddressDict = data.reduce((acc, item) => {
        acc[item.bot_symbol] = item.bot_address; // Usa item.bot_symbol como clave dinámica
        return acc;
      }, {});
      setAddressDict(newAddressDict);

      setItems(formattedItems); // Actualizamos el estado con los nuevos datos
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Llamamos a fetchData cada 3 segundos
  useEffect(() => {
    fetchData(); // Llamada inicial
    const interval = setInterval(fetchData, 3000); // Intervalo de 3 segundos
    return () => clearInterval(interval); // Limpiar intervalo cuando se desmonta el componente
  }, []);

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Pair</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Change</div>
          </div>
          <div className={styles.col}>
            <div>Add</div>
          </div>
        </div>
        {items.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.line}>
                {/* <button className={cn("favorite", styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button> */}
                <div className={styles.info}>
                <Link to={`/market/${x.title.toLowerCase()}`} className={styles.link}>
                  {x.title}
                </Link>
                  {/* <span>/{x.currency}</span> */}
                </div>
              </div>
            </div>
            <div className={styles.col}><div
                className={styles.change}
                style={{
                  color: x.priceChange > 0 ? "#00C076" : "#FF4D4F",
                }}
              >
                {x.lastPrice.slice(0, 6)}
              </div></div>
            <div className={styles.col}>
                {x.priceChange}% 
            </div>
            <div className={styles.col}>
            <button
                className={cn("button-stroke button-extra-small", styles.button)}
                onClick={(e) => {
                  e.stopPropagation();
                  addTokenToWallet(index);
                }}
              >
                Add
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Currency;
