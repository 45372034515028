import React, { useState } from "react";
import Profile from "../../components/Profile";
import Form from "./Form";
import Finish from "./Finish";

const breadcrumbs = [
  {
    title: "Bot Upload",
  },
];

const SubmitBot = () => {
  const [finish, setFinish] = useState(false);

  return (
    <Profile title="Bot Upload" breadcrumbs={breadcrumbs}>
      {finish ? <Finish /> : <Form goFinish={() => setFinish(true)} />}
    </Profile>
  );
};

export default SubmitBot;
