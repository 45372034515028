import React from "react";
import { useRecoilState } from "recoil";
import cn from "classnames";
import styles from "./Processing.module.sass";
import { transactionHashState } from "../../../../states/transactionHashState";
import { transactionStateState } from "../../../../states/transactionStateState";
import { buyAmountState } from "../../../../states/buyAmountState";
import ProgressBar from "../../../../components/ProgressBar";
import { transactionConfirmationsState } from "../../../../states/transactionConfirmationsState";

const color = "#faca32";

const Processing = () => {
  const [transactionHash, setTransactionHash] = useRecoilState(transactionHashState);
  const [transactionState, setTransactionState] = useRecoilState(transactionStateState);
  const [transactionConfirmations, setTransactionConfirmations] = useRecoilState(transactionConfirmationsState);
  const [buyAmount, setBuyAmount] = useRecoilState(buyAmountState);

  const processTransactionHash = (hash) => {
    if (hash == null) {
      return "Sending...";
    }
    return hash.substring(0, 6) + "..." + hash.substring(hash.length - 4);
  }

  const polygonScanLink = `https://polygonscan.com/tx/${transactionHash}`;

  const items = [
    {
      title: "Status",
      content: "Processing",
      color: color,
    },
    {
      title: "Transaction ID",
      content: processTransactionHash(transactionHash),
    },
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Almost There!{" "}
          <span role="img" aria-label="hourglass">
            ⏳
          </span>
        </div>
        <div className={styles.info}>
          Your withdrawal for <span style={{ color: color }}>{buyAmount} USDT</span>{" "}
          is being validated on the blockchain. This process may take a few minutes.
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
          {(transactionState == 1) ? 
            (<div className={styles.info}> Transaction sent: {processTransactionHash(transactionHash)} </div>) 
            : (transactionState == 2) ? 
            (<div className={styles.info}> Transaction completed: {processTransactionHash(transactionHash)} </div>)
            : (<div className={styles.info}> Transaction is beign processed. </div>)
          }
          <ProgressBar confirmations={transactionConfirmations} total={16} />
        <div className={styles.loadingDots}>
          <div className={styles.dot}></div>
          &nbsp;
          <div className={styles.dot}></div>
          &nbsp;
          <div className={styles.dot}></div>
        </div>
        <div className={styles.btns}>
          {(transactionState > 0) ? (<a
            href={polygonScanLink}
            target="_blank"
            rel="noopener noreferrer"
            className={cn("button-stroke", styles.button)}
          >
            View on PolygonScan
          </a>): null}
        </div>
      </div>
    </>
  );
};

export default Processing;
